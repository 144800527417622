import styled from 'styled-components';
import { SpinnerContainer } from '../Spinner/Spinner.styles';

export const BaseButton = styled.button`
  min-width: 165px;
  width: auto;
  height: 50px;
  letter-spacing: 0.5px;
  line-height: 50px;
  margin: 2px;
  padding: 0 25px 0 25px;
  font-size: 12px;
  background-color: black;
  color: white;
  text-transform: uppercase;
  font-family: 'Open Sans Condensed';
  font-weight: bolder;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  &:hover {
    background-color: white;
    color: black;
    border: 1px solid black;
  }
`

export const GoogleSignInButton = styled(BaseButton)`
  background-color: #4285f4;
  color: white;
  &:hover {
    background-color: #357ae8;
    border: none;
  }
`
export const InvertedButton = styled(BaseButton)`
  background-color: white;
  color: black;
  border: 1px solid black;
  &:hover {
    background-color: black;
    color: white;
    border: none;
  }
`
    export const BlueButton  = styled(BaseButton) `
    background-color:  #0EBFE9; 
    color: black; 
    border: 2px solid #357ae8; 
&:hover { 
      background-color:  #357ae8; 
      border: none; }  
    ` 
  export const ButtonSpinner = styled(SpinnerContainer)`
  width: 30px;
  height: 30px;
  `
